import React, { useState } from "react";
import tw, { styled } from "twin.macro";
import Select from "react-select";
import _ from "lodash";
import moment from "moment-timezone";
import ScheduleListGameItem from "./ScheduleListGameItem";
import { Popover } from "react-tiny-popover";
import Icon from "./Icon";
import { TZ } from "../constants";
import ScheduleListEventItem from "./ScheduleListEventItem";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const ListContainer = styled.div`
  ${tw`w-full flex flex-col pb-8`}
`;

const FilterContainer = styled.div`
  ${tw`flex flex-col items-center justify-center flex-wrap sm:items-start sm:flex-row xl:items-center mb-2 bg-white p-2 rounded border border-gray-200 sm:p-0 sm:border-0`}
`;

const InputContainer = styled.div`
  ${tw`w-[180px] mr-0 sm:mr-4 my-1 text-sm`}
`;

const Button = styled.button`
  ${tw`border border-gray-300 text-xs px-4 py-2.5 font-bold rounded my-1 disabled:text-gray-200`}
  color: ${(props) => props.color};
  &:not(:disabled) {
    border-color: ${(props) => props.color};
    border-opacity: 0.5;
  }
`;

const FilterButton = styled.button`
  ${tw`border border-gray-300 text-xs font-bold text-black rounded sm:mb-2 sm:ml-auto mr-1 flex items-center justify-center`}

  & > .material-icons {
    ${tw`text-[32px]`}
  }
`;
const ButtonContainer = styled.div`
  ${tw`flex items-center justify-end mb-2 `}
`;

const ActionButton = styled.button`
  ${tw`mx-1 flex items-center border border-gray-200 rounded not-disabled:hover:bg-gray-200 `}
  &:disabled > img {
    opacity: 0.2;
  }
  &:disabled > span {
    opacity: 0.2;
  }
  & > span {
    ${tw`ml-0.5 font-bold text-sm sm:text-base hidden sm:inline`}
  }
`;
const ActionSvg = styled.img`
  ${tw`w-8 h-8`}
`;

const WeekDayOptions = [
  {
    value: 1,
    label: "Monday",
  },
  {
    value: 2,
    label: "Tuesday",
  },
  {
    value: 3,
    label: "Wednesday",
  },
  {
    value: 4,
    label: "Thursday",
  },
  {
    value: 5,
    label: "Friday",
  },
  {
    value: 6,
    label: "Saturday",
  },
  {
    value: 0,
    label: "Sunday",
  },
];

const HomeAwayOptions = [
  {
    value: true,
    label: "Home",
  },
  {
    value: false,
    label: "Away",
  },
];

const PromoOptions = [
  {
    value: true,
    label: "Promo Games",
  },
  {
    value: false,
    label: "Non Promo Games",
  },
];

const ScheduleList = ({
  currentDate,
  games,
  events,
  leagues = [],
  teamsByName,
  homeGameColor,
  awayGameColor,
  promoGameColor,
  timezone,
  view,
  setView,
  color,
  site,
}) => {
  const breakpoints = useBreakpoint();
  const screenSize = Object.values(breakpoints).reduce(
    (a, b) => (b ? a + 1 : a),
    0
  );
  const isMobile = screenSize === 0;
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false);
  const [filters, setFilters] = useState({
    weekday: null,
    homeAway: null,
    opponent: null,
    // promo: null,
    location: null,
    league: null,
  });
  const opponentOptions = Object.keys(teamsByName).map((team) => ({
    value: team,
    label: team,
  }));

  const locationOptions = getLocationOptions(games, teamsByName);
  const leagueOptions = getLeagueOptions(games);

  const resetFilters = () => {
    setFilters({
      weekday: null,
      homeAway: null,
      opponent: null,
      // promo: null,
      location: null,
      league: null,
    });
  };
  let { filteredGames, filteredEvents, filteredGamesByTeamDate } =
    getFilteredEventsAndGames(
      games,
      events,
      filters,
      currentDate,
      timezone,
      teamsByName
    );

  const hasFilter =
    Object.values(filters).filter((value) => value !== null).length !== 0;

  const counterDict = {};
  filteredGames.forEach(({ node }) => {
    const { homeTeam, awayTeam, date } = _.get(node, "frontmatter", {});
    const teamDateKey = `${awayTeam}|${homeTeam}|${moment(date)
      .tz(TZ)
      .format("YYYY-MM-DD")}`;
    const sameTeamsHasMultiple =
      _.get(filteredGamesByTeamDate, [teamDateKey], []).length > 1;

    if (sameTeamsHasMultiple) {
      if (teamDateKey in counterDict) {
        counterDict[teamDateKey]++;
      } else {
        counterDict[teamDateKey] = 1;
      }
      _.set(node, "frontmatter.numberSuffix", counterDict[teamDateKey]);
    }
  });

  // combine and sort events/games
  const eventAndGames = _.sortBy(
    [...filteredGames, ...filteredEvents],
    (item) => {
      const { mdType, startDate, date } = item.node.frontmatter;
      if (mdType === "event") {
        return startDate;
      } else {
        return date;
      }
    }
  );

  const selectBorderStyle = "2px solid #9e9e9e";
  const Filters = (
    <FilterContainer>
      <InputContainer>
        <Select
          options={WeekDayOptions}
          value={filters.weekday}
          onChange={(option) => setFilters({ ...filters, weekday: option })}
          placeholder="Day of the Week"
          styles={{
            control: (baseStyle, state) => ({
              ...baseStyle,
              border: selectBorderStyle,
            }),
          }}
        />
      </InputContainer>
      <InputContainer>
        <Select
          options={HomeAwayOptions}
          value={filters.homeAway}
          onChange={(option) => setFilters({ ...filters, homeAway: option })}
          placeholder="Home/Away"
          styles={{
            control: (baseStyle) => ({
              ...baseStyle,
              border: selectBorderStyle,
            }),
          }}
        />
      </InputContainer>
      {!_.isEmpty(opponentOptions) && (
        <InputContainer>
          <Select
            options={opponentOptions}
            value={filters.opponent}
            onChange={(option) => setFilters({ ...filters, opponent: option })}
            placeholder="Opponent"
            styles={{
              control: (baseStyle) => ({
                ...baseStyle,
                border: selectBorderStyle,
              }),
            }}
          />
        </InputContainer>
      )}
      <InputContainer>
        <Select
          options={locationOptions}
          value={filters.location}
          onChange={(option) => setFilters({ ...filters, location: option })}
          placeholder="Location"
          styles={{
            control: (baseStyle) => ({
              ...baseStyle,
              border: selectBorderStyle,
            }),
          }}
        />
      </InputContainer>
      {site === "diamondbacks" && (
        <InputContainer>
          <Select
            options={leagueOptions}
            value={filters.league}
            onChange={(option) => setFilters({ ...filters, league: option })}
            placeholder="League"
            styles={{
              control: (baseStyle) => ({
                ...baseStyle,
                border: selectBorderStyle,
              }),
            }}
          />
        </InputContainer>
      )}
      {/* <InputContainer>
        <Select
          options={PromoOptions}
          value={filters.promo}
          onChange={option => setFilters({ ...filters, promo: option })}
          placeholder="Promo"
          styles={{
            control: baseStyle => ({
              ...baseStyle,
              border: selectBorderStyle,
            }),
          }}
        />
      </InputContainer> */}
      <Button
        onClick={() => resetFilters()}
        disabled={!hasFilter}
        color={color}
      >
        Clear Filters
      </Button>
    </FilterContainer>
  );

  return (
    <ListContainer>
      {isMobile ? (
        <>
          <ButtonContainer>
            <Popover
              isOpen={mobileFilterOpen}
              content={Filters}
              padding={8}
              boundaryInset={16}
              positions={["bottom"]}
              onClickOutside={() => setMobileFilterOpen(false)}
            >
              <FilterButton
                onClick={() => setMobileFilterOpen(!mobileFilterOpen)}
              >
                <Icon name="tune" />
              </FilterButton>
            </Popover>
            <ActionButton
              onClick={() => setView("list")}
              disabled={view === "list"}
            >
              <ActionSvg src="/img/svg/list.svg" alt="list_view" />
              <span>List</span>
            </ActionButton>
            <ActionButton
              onClick={() => setView("calendar")}
              disabled={view === "calendar"}
            >
              <ActionSvg src="/img/svg/calendar.svg" alt="calendar_view" />
              <span>Calendar</span>
            </ActionButton>
          </ButtonContainer>
        </>
      ) : (
        Filters
      )}

      {!_.isEmpty(eventAndGames) ? (
        eventAndGames.map((item, idx) => {
          const { mdType } = item.node.frontmatter;
          const isEvent = mdType === "event";
          if (isEvent) {
            return <ScheduleListEventItem event={item} />;
          } else {
            const league = leagues.find(
              (l) =>
                (l.node.frontmatter.name = item.node.frontmatter.competition)
            );
            return (
              <ScheduleListGameItem
                key={item.node.id}
                game={item}
                league={league}
                teamsByName={teamsByName}
                timezone={timezone}
                homeGameColor={homeGameColor}
                awayGameColor={awayGameColor}
                promoGameColor={promoGameColor}
                site={site}
                color={color}
              />
            );
          }
        })
      ) : (
        <div>Nothing to display</div>
      )}
    </ListContainer>
  );
};

export default ScheduleList;

const getFilteredEventsAndGames = (
  games = [],
  events = [],
  filters,
  currentDate,
  timezone,
  teamsByName,
  site
) => {
  let filteredGames = games.filter((game) => {
    const { homeTeam, date, awayTeam, competition } = game.node.frontmatter;
    let match = true;
    // filter month
    const monthValue = moment.tz(currentDate, timezone).month();
    const gameDate = moment.tz(date, timezone);
    if (gameDate.month() !== monthValue) {
      match = false;
    }

    // filter day of the week
    if (!_.isNil(filters.weekday)) {
      const gameWeekday = gameDate.day();
      if (gameWeekday !== filters.weekday.value) {
        match = false;
      }
    }

    // filter home game
    const homeGame = _.lowerCase(homeTeam) === site;
    if (!_.isNil(filters.homeAway)) {
      if (homeGame !== filters.homeAway.value) {
        match = false;
      }
    }

    // filter opponent
    const opposingTeam = homeGame ? awayTeam : homeTeam;
    if (!_.isNil(filters.opponent)) {
      if (opposingTeam !== filters.opponent.value) {
        match = false;
      }
    }

    // filter promo
    // if (!_.isNil(filters.promo)) {
    //   if (promoGame !== filters.promo.value) {
    //     match = false
    //   }
    // }

    // filter location
    if (!_.isNil(filters.location)) {
      const location = _.get(
        teamsByName[homeTeam],
        "node.frontmatter.location.town"
      );
      if (location !== filters.location.value) {
        match = false;
      }
    }

    // filter league
    if (!_.isNil(filters.league)) {
      if (competition !== filters.league.value) {
        match = false;
      }
    }

    return match;
  });
  const hasFilter =
    Object.values(filters).filter((value) => value !== null).length !== 0;

  let filteredEvents = events.filter((event) => {
    const { startDate, endDate } = event.node.frontmatter;
    let match = true;
    // filter month
    const monthValue = moment.tz(currentDate, timezone).month();
    const eventStartDate = moment.tz(startDate, timezone);
    const eventEndDate = moment.tz(endDate, timezone);
    if (
      eventStartDate.month() > monthValue ||
      eventEndDate.month() < monthValue
    ) {
      match = false;
    }

    // other filter (currently only applies to games)
    if (hasFilter) {
      match = false;
    }
    return match;
  });

  const filteredGamesByTeamDate = _.groupBy(filteredGames, ({ node }) => {
    const { homeTeam, awayTeam, date } = _.get(node, "frontmatter", {});
    return `${awayTeam}|${homeTeam}|${moment(date)
      .tz(TZ)
      .format("YYYY-MM-DD")}`;
  });

  return { filteredGames, filteredEvents, filteredGamesByTeamDate };
};

const getLocationOptions = (games, teamsByName) => {
  const gamesWithUniqueHomeTeam = _.uniqBy(
    games,
    (game) => game.node.frontmatter.homeTeam
  );

  const locationOptions = gamesWithUniqueHomeTeam.map((game) => {
    const { homeTeam } = game.node.frontmatter;
    const homeTeamDoc = teamsByName[homeTeam];

    const location = _.get(homeTeamDoc, "node.frontmatter.location.town", "");
    const fieldName = _.get(
      homeTeamDoc,
      "node.frontmatter.location.fieldName",
      ""
    );
    return {
      value: location,
      label: `${fieldName} ${location}`,
    };
  });

  const uniqueLocationOptions = _.uniqBy(
    locationOptions,
    (option) => option.label
  );

  return uniqueLocationOptions;
};

const getLeagueOptions = (games) => {
  const gamesWithUniqueHomeTeam = _.uniqBy(
    games,
    (game) => game.node.frontmatter.competition
  );
  const leagueOptions = gamesWithUniqueHomeTeam.map((game) => ({
    value: game.node.frontmatter.competition,
    label: game.node.frontmatter.competition,
  }));
  return leagueOptions;
};
