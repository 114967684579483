import React, { useState, useMemo } from "react";
import tw, { styled } from "twin.macro";

import { graphql } from "gatsby";
import _ from "lodash";
import moment from "moment-timezone";
import Layout from "../components/Layout";
import Calendar from "../components/Calendar";
import Header from "@shared/components/Header";
import Icon from "@shared/components/Icon";
import ScheduleList from "@shared/components/ScheduleList";
import { TZ } from "@shared/constants";
import { addNumberSuffix, getStats } from "@shared/utils";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { MAIN_COLOR, SITE } from "../config";

const Container = styled.div`
  ${tw`w-full flex flex-col p-5`}
`;

const TopBar = styled.div`
  ${tw`w-full flex flex-col sm:flex-row items-center sm:justify-between sticky py-4 text-xl left-0`}
`;

const ActionContainer = styled.div`
  ${tw`flex justify-center items-center`}
`;
const ButtonContainer = styled.div`
  ${({ view }) =>
    view === "calendar"
      ? tw`flex mt-4 sm:my-0 [align-self: flex-end]`
      : tw`hidden sm:flex`}
  ${tw`sm:flex `}
`;
const DateContainer = styled.div`
  ${tw`text-white py-0.5 px-4 border-[1px] border-gray-300 bg-[#9561a9] text-xl [line-height: 2em] min-w-[160px] text-center`}
`;

const ToggleButton = styled.button`
  ${tw`border border-gray-300 bg-[#9561a9] h-9 px-3 flex justify-center items-center`}

  &:disabled {
    opacity: 0.3;
  }
  & > .material-icons {
    ${tw`text-2xl text-white`}
  }
`;

const ActionButton = styled.button`
  ${tw`mx-1 sm:mx-2 flex items-center border border-gray-200 sm:px-2 sm:py-1 rounded not-disabled:hover:bg-gray-200 `}
  &:disabled > img {
    opacity: 0.2;
  }
  &:disabled > span {
    opacity: 0.2;
  }
  & > span {
    ${tw`ml-0.5 font-bold text-sm sm:text-base hidden sm:inline`}
  }
`;

const Svg = styled.img`
  ${tw`w-4 h-4`}
`;

const ActionSvg = styled.img`
  ${tw`w-8 h-8`}
`;

const LiveStreamContainer = styled.div`
  ${tw`flex flex-col items-center mt-4`}
`;
const LiveStreamText = styled.div`
  ${tw`text-2xl sm:text-3xl my-1 text-black/70 text-center`}
`;

const LiveButtonContainer = styled.a`
  ${tw`bg-[#9561a9] text-white flex items-center px-4 py-0.5 text-sm font-bold hover:not-disabled:opacity-80 transition-opacity rounded my-4`}
  & > .material-icons {
    ${tw`mr-2 text-4xl`}
  }
`;
const LinkContainer = styled.div`
  ${tw`flex items-center`}
`;

const BoxScoreLink = styled.a`
  ${tw`bg-[#9561a9] text-white flex items-center mx-1 px-4 py-0.5 text-sm font-bold hover:not-disabled:opacity-80 transition-opacity rounded my-4`}
  & > .material-icons {
    ${tw`text-4xl`}
  }
`;

const Record = styled.div`
  ${tw`flex justify-center`}
`;

const RecordItem = styled.div`
  ${tw`p-2 text-2xl sm:text-4xl font-bold`}
`;

const SchedulePage = (props) => {
  const {
    data: {
      markdownRemark: {
        frontmatter: {
          seo,
          title,
          homeGameColor,
          awayGameColor,
          promoGameColor,
        },
      },
      allGames,
      allTeams,
      allLeagues,
      allEvents,
      homepage,
    },
    pageContext,
    location,
  } = props;

  const allGameNodes = allGames.edges;
  const allLeagueNodes = allLeagues.edges;
  const allEventNodes = allEvents.edges;
  const pageYear = pageContext.year;

  const defaultDate = getDefaultDate(allGameNodes, pageYear, TZ);
  const currentLiveGame = allGameNodes.find((game) => {
    const timePassed = moment
      .tz(TZ)
      .diff(game.node.frontmatter.date, "minutes");
    return timePassed <= 180 && timePassed >= 0;
  });
  const teamsByName = _.keyBy(allTeams.edges, "node.frontmatter.name");
  const breakpoints = useBreakpoint();
  const isMobile =
    Object.values(breakpoints).reduce((a, b) => (b ? a + 1 : a), 0) === 0;
  const [view, setView] = useState(isMobile ? "list" : "calendar");
  // in ISO format
  const [currentDate, setCurrentDate] = useState(defaultDate);

  const handleChangeMonth = (increment) => {
    const newDate = moment
      .tz(currentDate, TZ)
      .add(increment, "month")
      .toISOString();
    setCurrentDate(newDate);
  };
  const record = useMemo(
    () => getTeamRecord(allGameNodes, "Diamondbacks"),
    [allGameNodes]
  );

  const watchLiveLink = homepage.frontmatter.hero.watchLiveLink;
  const teamSeasonLink = homepage.frontmatter.hero.teamSeasonLink;
  const momentCurrDate = moment.tz(currentDate, TZ);
  const liveScoreLink = _.get(
    currentLiveGame,
    "node.frontmatter.liveScoreLink"
  );
  addNumberSuffix(allGameNodes, true);
  return (
    <Layout
      seoTitle={seo?.title || title}
      seoDescription={seo?.description}
      location={location}
    >
      <Container>
        <Header
          title={`${momentCurrDate.format("YYYY")} Schedule`}
          color={MAIN_COLOR}
          site={SITE}
        />
        <LiveStreamContainer>
          {/* <LiveStreamText>Live Stream!</LiveStreamText>
          <LiveStreamText>Webcast available for all games.</LiveStreamText> */}
          <LinkContainer>
            {/* <LiveButtonContainer
              href={watchLiveLink || teamSeasonLink}
              target="_blank"
              disabled={_.isEmpty(watchLiveLink) && _.isEmpty(teamSeasonLink)}
            >
              <Icon name="videocam" /> <div>WATCH NOW</div>
            </LiveButtonContainer> */}
            {!_.isNil(currentLiveGame) && (
              <BoxScoreLink
                href={liveScoreLink || teamSeasonLink}
                target="_blank"
              >
                <Icon name="view_module" />
              </BoxScoreLink>
            )}
          </LinkContainer>
        </LiveStreamContainer>

        <Record>
          <RecordItem>Win: {record.win}</RecordItem>
          <RecordItem>Loss: {record.lose}</RecordItem>
        </Record>

        <TopBar>
          <ActionContainer>
            <ToggleButton
              onClick={() => handleChangeMonth(-1)}
              disabled={momentCurrDate.month() === 0}
            >
              <Icon name="arrow_left" />
            </ToggleButton>
            <DateContainer>{momentCurrDate.format("MMMM YYYY")}</DateContainer>
            <ToggleButton
              onClick={() => handleChangeMonth(1)}
              disabled={momentCurrDate.month() === 11}
            >
              <Icon name="arrow_right" />
            </ToggleButton>
          </ActionContainer>
          <ButtonContainer view={view}>
            <ActionButton
              onClick={() => setView("list")}
              disabled={view === "list"}
            >
              <ActionSvg src="/img/svg/list.svg" alt="list_view" />
              <span>List</span>
            </ActionButton>
            <ActionButton
              onClick={() => setView("calendar")}
              disabled={view === "calendar"}
            >
              <ActionSvg src="/img/svg/calendar.svg" alt="calendar_view" />
              <span>Calendar</span>
            </ActionButton>
          </ButtonContainer>
        </TopBar>
        {view === "list" && (
          <ScheduleList
            currentDate={currentDate}
            games={allGameNodes}
            events={allEventNodes}
            leagues={allLeagueNodes}
            teamsByName={teamsByName}
            homeGameColor={homeGameColor}
            awayGameColor={awayGameColor}
            promoGameColor={promoGameColor}
            timezone={TZ}
            setView={setView}
            view={view}
            site={SITE}
            color={MAIN_COLOR}
          />
        )}
        {view === "calendar" && (
          <Calendar
            currentDate={currentDate}
            games={allGameNodes}
            events={allEventNodes}
            leagues={allLeagueNodes}
            teamsByName={teamsByName}
            homeGameColor={homeGameColor}
            awayGameColor={awayGameColor}
            promoGameColor={promoGameColor}
            timezone={TZ}
          />
        )}
      </Container>
    </Layout>
  );
};

export default SchedulePage;

export const pageQuery = graphql`
  query SchedulePageQuery($year: Int) {
    markdownRemark(frontmatter: { templateKey: { eq: "schedule-page" } }) {
      frontmatter {
        seo {
          title
          description
        }
        title
        homeGameColor
        awayGameColor
        promoGameColor
      }
    }
    allGames: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "game-post" }, yr: { eq: $year } }
      }
      sort: { order: ASC, fields: frontmatter___date }
    ) {
      edges {
        node {
          frontmatter {
            yr
            date
            homeTeam
            awayTeam
            rainOut
            competition
            field
            liveScoreLink
            promoDescriptions
            bgColor
            stats {
              inningStats {
                home
                away
              }
            }
          }
          id
          html
        }
      }
    }
    allTeams: allMarkdownRemark(
      filter: { frontmatter: { mdType: { eq: "team" } } }
    ) {
      edges {
        node {
          frontmatter {
            name
            logo {
              childImageSharp {
                gatsbyImageData(height: 75)
              }
            }
            location {
              fieldName
              abbreviation
              town
              address
            }
          }
        }
      }
    }
    allLeagues: allMarkdownRemark(
      filter: { frontmatter: { mdType: { eq: "league" } } }
    ) {
      edges {
        node {
          frontmatter {
            name
            color
            link
          }
        }
      }
    }
    allEvents: allMarkdownRemark(
      filter: { frontmatter: { mdType: { eq: "event" } } }
      sort: { order: ASC, fields: frontmatter___date }
    ) {
      edges {
        node {
          frontmatter {
            mdType
            name
            startDate
            endDate
            highlightColor
          }
        }
      }
    }
    homepage: markdownRemark(
      frontmatter: { templateKey: { eq: "index-page" } }
    ) {
      frontmatter {
        hero {
          watchLiveLink
          teamSeasonLink
        }
      }
    }
  }
`;

const getDefaultDate = (games, year, timezone) => {
  const currentDate = moment().tz(timezone).toISOString();
  const upcomingGamesForCurrentYear = games.filter((game) => {
    const isCurrentYear =
      moment.tz(game.node.frontmatter.date, timezone).year() === parseInt(year);
    const isFutureGame = game.node.frontmatter.date > currentDate;
    return isCurrentYear && isFutureGame;
  });

  const mostRecentUpcomingGameDate = _.get(
    upcomingGamesForCurrentYear,
    "0.node.frontmatter.date"
  );
  if (mostRecentUpcomingGameDate) {
    const date = moment(mostRecentUpcomingGameDate)
      .tz(timezone)
      .startOf("month")
      .toISOString();
    return date;
  } else {
    return parseInt(year) === moment.tz(timezone).year()
      ? moment.tz(timezone).toISOString()
      : moment
          .tz(timezone)
          .year(parseInt(year))
          .month(0)
          .startOf("month")
          .toISOString();
  }
};

const getTeamRecord = (allGames, team) => {
  let record = { total: 0, win: 0, lose: 0 };
  allGames.forEach((obj) => {
    const { awayTeam, homeTeam, stats } = obj.node.frontmatter;
    const gameForTeam = [awayTeam, homeTeam].includes(team);
    const homeTeamInnings = _.get(stats, "inningStats.home");
    const awayTeamInnings = _.get(stats, "inningStats.away");
    if (
      !gameForTeam ||
      _.isEmpty(homeTeamInnings) ||
      _.isEmpty(awayTeamInnings)
    ) {
      return;
    }
    const { score } = getStats(stats.inningStats);

    const isHomeGameForTeam = homeTeam === team;
    const homeGameVictory = score.home > score.away;
    const awayGameVictory = score.away > score.home;
    // draw is not counted
    record.total += 1;
    if (isHomeGameForTeam) {
      record.win += homeGameVictory ? 1 : 0;
      record.lose += awayGameVictory ? 1 : 0;
    } else {
      record.win += awayGameVictory ? 1 : 0;
      record.lose += homeGameVictory ? 1 : 0;
    }
  });
  return record;
};
