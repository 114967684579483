import React, { useState } from "react";
import tw, { styled } from "twin.macro";
import _ from "lodash";
import moment from "moment-timezone";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { getContrastText, hasPassed } from "../utils";
import Icon from "./Icon";
import { TZ } from "../constants";
import useStats from "../hooks/useStats";
import CompetitionChip from "./CompetitionChip";

const ListItemWrapper = styled.div`
  ${tw`w-full text-sm sm:text-lg py-3 px-2`}
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#ffffff")};
  color: ${(props) => (props.color ? props.color : "#000000")};
  box-shadow: 0 -1px 0 0 #999999, 0 1px 0 0 #999999;
  ${({ striped, bgColor, promoColor }) =>
    striped &&
    `background: repeating-linear-gradient(135deg, ${promoColor},${promoColor} 30px,${bgColor} 30px,${bgColor} 60px);`}
`;
const DateContainer = styled.div`
  ${tw`text-center`}
`;
const Date = styled.div`
  ${tw`text-xs opacity-80`}
  color: ${(props) => (props.color ? props.color : "#000000")};
`;
const Day = styled.div`
  ${tw`opacity-80`}
`;
const InfoContainer = styled(Link)`
  ${tw`flex flex-col w-[80px] sm:w-[160px]`}
`;

const TeamsContainer = styled.div`
  ${tw`flex justify-between items-center gap-1`}
`;
const Team = styled.div`
  ${tw`flex gap-1 justify-between flex-1`}
`;
const PaddingDiv = styled.div`
  ${tw`w-0 sm:w-[50px]`}
`;
const TeamIconContainer = styled.div`
  ${tw`flex justify-center items-center sm:w-[20%] max-w-[20em] h-[100px]`}
`;
const TeamName = styled.div`
  ${tw`hidden sm:flex items-center font-bold w-[70px] sm:w-[100px] whitespace-nowrap text-ellipsis`}
  ${({ align }) =>
    align === "left" ? tw`justify-start ml-2` : tw`justify-end mr-2`}
`;

const Score = styled.div`
  ${tw`font-bold text-2xl sm:text-4xl flex items-center font-bold w-[30px] sm:w-[30px] whitespace-nowrap text-ellipsis`}
`;
const FieldName = styled.div`
  ${tw`text-xs text-center`}
`;
const Address = styled.div`
  ${tw`hidden text-xs text-center`}
`;
const Time = styled.div`
  ${tw`text-sm sm:text-base mt-2`}
`;
const MediaContainer = styled.div`
  ${tw`hidden md:flex md:flex-col md:justify-center text-[10px]`}
`;

const ActionContainer = styled.div`
  ${tw`flex flex-row justify-center items-center ml-auto`}
`;
const GameButtonContainer = styled.div`
  ${tw`text-center relative flex justify-center`}
`;
const GameButtonLink = styled.a`
  ${tw`bg-black hover:opacity-80 text-white px-2 py-1 text-center text-xs sm:w-[100px] sm:mx-2 rounded`}
`;
const ToggleIcon = styled.button`
  ${tw`disabled:opacity-20`}
`;
const DescriptionContainer = styled.div`
  ${tw`text-sm font-bold py-1 text-center`}
`;
const DescriptionTitle = styled.span`
  ${tw``}
`;
const DescriptionList = styled.span`
  ${tw`py-2`}
`;
const DescriptionItem = styled.span`
  ${tw`flex items-center py-1`}

  > img {
    ${tw`mr-2`}
  }
`;

const CollapseContainer = styled.div`
  ${tw`px-2 py-4 relative flex flex-col
  after:content-['']
  after:absolute
  after:h-[1px] 
  after:bottom-0
  after:left-0
  after:right-0
  after:w-full 
  after:mx-auto
  after:block
  after:mb-2
  `}
  border-top: none;
  &:after {
    background-color: ${(props) => (props.bgColor ? props.bgColor : "#ffffff")};
  }
`;

const ActionSvg = styled.img`
  ${tw`w-4 h-4 mx-1 inline`}
`;
const TextButton = styled.span`
  ${tw`px-1 cursor-pointer inline underline`}
`;

// const MDContainer = styled.div`
//   ${tw``}
// `

const ScheduleListGameItem = ({
  game,
  teamsByName,
  timezone,
  homeGameColor,
  awayGameColor,
  promoGameColor,
  site,
}) => {
  // const [open, setOpen] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    homeTeam,
    awayTeam,
    bgColor,
    numberSuffix,
    date,
    ticketLink,
    promoGame,
    promoDescriptions,
    tv,
    radio,
    showBuyTicket,
    stats,
    field,
    rainOut,
  } = game.node.frontmatter;
  const { score } = useStats({ inningStats: stats.inningStats });
  const html = game.node.html;
  const gameDate = moment(date).tz(TZ);
  const homeGame = _.lowerCase(homeTeam) === site;
  const awayTeamDoc = teamsByName[awayTeam];
  const homeTeamDoc = teamsByName[homeTeam];
  const opponent = teamsByName[homeGame ? awayTeam : homeTeam];

  const isCompletedGame = hasPassed(gameDate);
  const slug = `${_.kebabCase(
    `${awayTeam}-${homeTeam}-${moment(date).tz(TZ).format("YYYY-MM-DD")}${
      numberSuffix ? `-${numberSuffix}` : ""
    }`
  )}`;
  let backgroundColor = homeGame ? homeGameColor : awayGameColor;
  if (bgColor) {
    backgroundColor = bgColor;
  }
  const color = getContrastText(backgroundColor);
  // TODO: add link
  const promoDescriptionString = (promoDescriptions || []).join(", ");
  const STR_LEN_LIMIT = 40;
  return (
    <>
      <ListItemWrapper
        color={color}
        bgColor={backgroundColor}
        striped={
          ((site === "dawgs" && promoGame) ||
            (site === "diamondbacks" && !_.isNil(promoDescriptions))) &&
          !bgColor
        }
        promoColor={promoGameColor}
      >
        <TeamsContainer>
          <Team>
            <PaddingDiv />
            <TeamIconContainer>
              <GatsbyImage
                image={getImage(
                  _.get(awayTeamDoc, "node.frontmatter.logo", null)
                )}
                alt="away team"
              />
            </TeamIconContainer>
            <TeamName align="left">
              {_.get(awayTeamDoc, "node.frontmatter.name", "Team Name")}
            </TeamName>
            <Score>{score.away}</Score>
          </Team>
          <InfoContainer to={slug}>
            <DateContainer>
              <Date color={color}>{gameDate.format("dddd, MMM Do YYYY")}</Date>
              <Time>
                {isCompletedGame
                  ? "FINAL"
                  : rainOut
                  ? "RAIN OUT"
                  : gameDate.format("h:mm a")}
              </Time>
            </DateContainer>
            <FieldName>
              {site === "dawgs" &&
                homeTeamDoc.node.frontmatter.location.fieldName}
              {site === "diamondbacks" && field && field}
            </FieldName>
            <Address>{homeTeamDoc.node.frontmatter.location.address}</Address>
          </InfoContainer>
          <Team>
            <Score>{score.home}</Score>
            <TeamName align="right">
              {homeTeamDoc.node.frontmatter.name}
            </TeamName>
            <TeamIconContainer>
              <GatsbyImage
                image={getImage(homeTeamDoc.node.frontmatter.logo)}
                alt="home team"
              />
            </TeamIconContainer>
            <PaddingDiv />
          </Team>
        </TeamsContainer>
        {site === "dawgs" &&
          (homeGame || showBuyTicket) &&
          !isCompletedGame && (
            <>
              <GameButtonContainer>
                <GameButtonLink href={ticketLink || "#"} target="_blank">
                  BUY TICKETS
                </GameButtonLink>
              </GameButtonContainer>
            </>
          )}
        {site === "diamondbacks" && homeGame && !isCompletedGame && (
          <>
            <GameButtonContainer>
              <CompetitionChip competition={league} />
            </GameButtonContainer>
          </>
        )}
        {promoGame && !_.isEmpty(promoDescriptions) && (
          <DescriptionContainer>
            <ActionSvg src="/img/svg/asterisk.svg" alt="promo_game" />{" "}
            Promotion:{" "}
            {promoDescriptionString.length > STR_LEN_LIMIT
              ? isExpanded
                ? promoDescriptionString
                : promoDescriptionString.slice(0, STR_LEN_LIMIT) + "..."
              : promoDescriptionString}
            {promoDescriptionString.length > STR_LEN_LIMIT && (
              <TextButton onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? "read less" : "read more"}
              </TextButton>
            )}
          </DescriptionContainer>
        )}
        {/* {(html || (promoGame && promoDescriptions)) && (
          <ActionContainer>
            <ToggleIcon
              onClick={() => setOpen(!open)}
              disabled={!(html || (promoGame && promoDescriptions))}
            >
              {open ? (
                <Icon name="arrow_drop_up" />
              ) : (
                <Icon name="arrow_drop_down" />
              )}
            </ToggleIcon>
          </ActionContainer>
        )} */}
      </ListItemWrapper>
      {/* {open && (
        <CollapseContainer bgColor={bgColor} color={color}>
          {promoGame && !_.isEmpty(promoDescriptions) && (
            <DescriptionContainer>
              <DescriptionList>
                {(promoDescriptions || []).map(str => (
                  <DescriptionItem>{str}</DescriptionItem>
                ))}
              </DescriptionList>
            </DescriptionContainer>
          )}
          <MDContainer dangerouslySetInnerHTML={{ __html: html }} />
        </CollapseContainer>
      )} */}
    </>
  );
};

export default ScheduleListGameItem;
